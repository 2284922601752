<script>
import { BIconEye, BIconPencilSquare, BIconTrash} from 'bootstrap-vue';
import RolePermission from '../UserRole/rolePermission.vue'
import roles from '../../userRole.json';
import { mapState} from 'vuex';
import permissions from '../../permission-base.json';

export default {
	name: 'UserRole',
	components: {
        BIconEye,
        BIconPencilSquare,
        BIconTrash,
        RolePermission,
	},
    props:[],
	data(){
		return{
            fields: [
                { key: 'roleName', label:"Name", sortable: true },
                { key: 'description', label:"Description", sortable: true },
                { key: 'permissions', label:"Permissions", sortable: false },
                { key: 'view', label:''},
                { key: 'edit', label:''},
                { key: 'delete', label:''}
            ],
            userRolePermissions: permissions,
			userRoleData: roles,
            crudPermission:"",
            openModal: false,
            btnOperation:'',
            saveDetails: false,
            loading: false,
            modalType:'',
            deleteModalShow: false,
            viewData: {},
            editData: {},
            deleteId:'',
            showToast: false,
		}
	},
	computed: {
        ...mapState(['userRole']),
	},
	mounted() {
        this.$store.dispatch('getUserRole')
        
	},
	methods:{
        addNewRole(){
            // this.modalType= true
            this.btnOperation="Add"
            this.showModal();
            // this.openModal= true
            console.log("working", this.openModal)
        },
        editRole(editData){
            this.btnOperation="Edit"
            this.editData = editData
            this.showModal();
        },
        viewRole(viewData){
            this.btnOperation="view"
            this.viewData = viewData
            this.showModal();
        },
        deleteRole(permissionData){
            this.deleteModalShow=true
            this.deleteId=permissionData.id
        },
        showModal(){
            this.openModal= true
        },
        hideModal(){
            this.openModal= false
        },
        userPermission(permissionData){
            let description = [];
            let descriptionString='';
            if(permissionData.hasAddPermission){
                description.push("Create")
            }
            if(permissionData.hasEditPermission){
                description.push("Edit")
            }
            if(permissionData.hasDeletePermission){
                description.push("Delete")
            }
            if(permissionData.hasViewPermission){
                description.push("View")
            }
            if(description.length != 0){
                descriptionString = permissionData.text+" : "+description;
                descriptionString.replaceAll(",", "/")
            }
            return descriptionString
        },
        deleteUserRole(){

            this.$store.dispatch('deleteUserRole', this.deleteId)
            .then(() => {
                this.deleteModalShow = false;
                this.showToastMessage('Role deleted successfully!', 'success');
                this.$store.dispatch('getUserRole')
            })
            .catch(error => {
                console.error('Error deleting role:', error);
                this.showToastMessage('Error deleting role. Please try again.', 'danger');
            });
        },
        loader(value){
			console.log("Loading...")
			this.loading = value;
		},
        showToastMessage(message, variant) {
            this.showToast = true;
            this.$bvToast.toast(message, {
            title: '',
            autoHideDelay: 5000,
            appendToast: true,
            variant: variant,
            });
        },
        nonEditableRoles(roleName){
            if(roleName =='client-sharing-user' || roleName =='Primary Lawyer' || roleName =='assistant-level-1' || roleName =='Separating Party (Level 1)'){
                return true
            } else {
                return false
            }
        }
    }
};
</script>

<style>
.btn-color{
    background-color: #5b9ebb;
}
.font-color{
    color: #3F6772;
}
.btn-styling{
    background-color: white;
    border: white;
}
.badge-spacing {
    margin-right: 10px;
  }
</style>

<template>
    <b-container class="mt-5">
        <b-card class="home-wrapper">
            <b-row>
				<b-col class="text-right mb-3 d-flex justify-content-end">
                    <template>
					<b-button v-b-modal="'rolePermission'" class="btn-color" variant="primary" @click="addNewRole()" data-testid="add-new-role">
						+ Add A Role
					</b-button></template>
                    <template v-if="openModal && btnOperation=='Add'">
                        <role-permission
                        title= "Add A Role Level"
                        :permissionRoleData="userRolePermissions"
                        :show-modal="showModal"
                        :hide-modal="hideModal"
                        :save-details="saveDetails"
                        :btn-operation="btnOperation"
                        class="modal-body"
                        @isLoading="loader"
                        />
                    </template>
				</b-col>
			</b-row>
            <!-- <div>
                <b-table
                :items="userRoleData"
                :fields="fields"
                responsive="sm"
                ></b-table>

            </div> -->
            <div>
                <b-table v-if="userRole.length > 0" small :fields="fields" :items="userRole" responsive="sm" sort-icon-left>
                  <template #cell(roleName)="userRoleObj">
                    <div class="font-color">{{ userRoleObj.item.roleName}} </div>
                  </template>
            
                  <template #cell(description)="userRoleObj">
                    <div class="font-color">{{ userRoleObj.item.description }}</div>
                  </template>
            
                  <template #cell(permissions)="userRoleObj">
                    <!-- <div v-for="{permission, index} in userRole.item.permissions" :key="index">
                        {{ userRole.item.permissions }}
                    </div> -->
                    <template v-for="item in userRoleObj.item.permissions">
                        <b-badge v-if="userPermission(item)!=''" pill class="badge-spacing"> {{ userPermission(item) }} </b-badge>
                    </template>
                  </template>
            
                  <template #cell(view)="{item: userRoleObj}">
                    <button v-b-modal="'rolePermission'" class='btn-styling' :data-testid="'view-role'+userRoleObj.id" @click="viewRole(userRoleObj)"><b-icon-eye/> </button>
                    <template v-if="openModal && btnOperation=='view'">
                        <role-permission
                            title= ""
                            :permissionRoleData="viewData"
                            :show-modal="showModal"
                            :hide-modal="hideModal"
                            :save-details="saveDetails"
                            :btn-operation="btnOperation"
                            class="modal-body"
                            @isLoading="loader"
                        />
                    </template>
                  </template>
                  <template #cell(edit)="{item: userRoleObj}">
                   <button v-b-modal="'rolePermission'" :disabled="nonEditableRoles(userRoleObj.roleName)" class='btn-styling' :data-testid="'edit-role'+userRoleObj.id" @click="editRole(userRoleObj)"> <b-icon-pencil-square/> </button>
                    <template v-if="openModal && btnOperation=='Edit'">
                        <role-permission
                        title= "Edit A Role Level"
                        :permissionRoleData="editData"
                        :show-modal="showModal"
                        :hide-modal="hideModal"
                        :save-details="saveDetails"
                        :btn-operation="btnOperation"
                        class="modal-body"
                        @isLoading="loader"
                        />
                    </template>
                  </template>
                  <template #cell(delete)="{item: userRoleObj}" >
                    <button class='btn-styling' :data-testid="'delete-role'+userRoleObj.id" :disabled="nonEditableRoles(userRoleObj.roleName)" @click="deleteRole(userRoleObj)"><b-icon-trash/></button>
                        <b-modal v-model="deleteModalShow" @hide="hideModal"
                            @ok="deleteRole()">
                            <div class="d-block">Would you like to delete the role?</div>
                            <template #modal-footer="{ ok, cancel }">
                                <template>
                                <b-button size="sm" variant="pastel-blue" @click="cancel()" data-testid="cancel-button"> Cancel </b-button>
                                <b-button size="sm" variant="primary" @click="deleteUserRole(userRoleObj)" data-testid="delete-button">
                                    Delete
                                </b-button></template>
                            </template>
                            </b-modal>
                  </template>
                </b-table>
                <b-skeleton-table :rows="6" :columns="3" v-else />
                <b-toast v-if="showToast" :no-fade="true" :append-to-body="true" />
              </div>
              <template>
                <div
                    v-if="loading"
                    class="
                        text-center
                        w-100
                        d-flex
                        justify-content-center
                        align-items-center
                    "
                    style="height: 200px"
                >
                    <b-spinner
                        style="width: 3rem; height: 3rem"
                        variant="primary"
                        label="Loading..."
                    />
                </div>
            </template>
        </b-card>
    </b-container>
    
    
</template>
